import React from "react";

const Home = () => {
  return (
    <div className="textStyle">
      <h2>Home</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed
        placerat erat, a pellentesque metus. Phasellus purus nisl, suscipit at
        scelerisque at, faucibus a nibh. Aenean et finibus nunc, ut ultricies
        velit. Nunc finibus vel est ac semper. Curabitur lacinia turpis sit amet
        elit pellentesque vulputate. Nunc fringilla ex justo, vel vestibulum
        tellus rutrum in. Nunc non tellus eget diam gravida eleifend ac bibendum
        elit. In volutpat a quam at facilisis. Nam a mi quis mauris tempor
        dictum. Vestibulum eleifend velit luctus nunc lacinia scelerisque. Proin
        eget mauris diam. In placerat vitae felis et iaculis. Aliquam et
        tincidunt ex, sed lobortis libero. Suspendisse ipsum arcu, tincidunt
        vitae metus nec, auctor vulputate sapien. Nulla vitae lorem a est
        vehicula condimentum sed quis turpis. Maecenas hendrerit orci quis ipsum
        suscipit egestas. Duis venenatis eros vitae neque vulputate, at lobortis
        eros egestas. Proin fermentum eu erat at iaculis.
      </p>
    </div>
  );
};
export default Home;
