import React from "react";

const Vitae = () => {
  return (
    <div className="textStyle">
      <h2>Vitae</h2>
    </div>
  );
};
export default Vitae;
